<script setup lang="ts">
import { useRouter } from "vue-router";
import { StrapiBlocks } from "vue-strapi-blocks-renderer";
import Button from "primevue/button";
import { useApiStore } from "@/stores/useApiStore";
import WorldMap from "@/components/WorldMap.vue";
import { ROUTES } from "@/models/routes";

const router = useRouter();
const apiStore = useApiStore();

const onTeaserClick = (path: string) => {
  router.push(path);
};
</script>

<template>
  <div class="home-row welcome_text_container">
    <h2 class="page__title">About "AI Policy Observatory"</h2>
    <div v-if="apiStore.data.editorialTexts.Welcome" class="welcome_text">
      <StrapiBlocks :content="apiStore.data.editorialTexts.Welcome" />

      <div class="welcome_text_image_container">
        <img
          class="welcome_text_image"
          src="@/assets/images/AIPO_Logo_W_1_blue2.svg" />
      </div>
    </div>
  </div>
  <div class="home-row world-map">
    <WorldMap></WorldMap>
  </div>
  <div class="home-row home-teaser-container">
    <div class="home-teaser">
      <h2>Comparison</h2>
      <p>Comparitive overview of AI Policies</p>
      <Button
        @click="onTeaserClick(ROUTES.COMPARE.path)"
        class="ai-teaser-button"
        size="large">
        Compare
      </Button>
    </div>
    <div class="home-teaser">
      <h2>Sources</h2>
      <p>Curated Sources on AI Regulations</p>
      <Button
        @click="onTeaserClick(ROUTES.SOURCES.path)"
        class="ai-teaser-button"
        size="large">
        Sources
      </Button>
    </div>
    <div class="home-teaser">
      <h2>Team</h2>
      <p>Contributing Experts</p>
      <Button
        class="ai-teaser-button"
        @click="onTeaserClick(ROUTES.TEAM.path)"
        size="large">
        Team
      </Button>
    </div>
  </div>
</template>
<style lang="scss">
@use "@/assets/variables.scss" as *;

.welcome_text_container {
  max-width: $home-card-max-width;
  margin: 0 auto;
}

.welcome_text {
  display: flex;
  gap: 16px;
}
.welcome_text p {
  max-width: 90ch;
  flex: 1;
}
.welcome_text_image_container {
  flex: 0;
  padding-left: 16px;
  padding-right: 8px;
  padding-top: 16px;
  margin-left: auto;
}
.welcome_text_image {
  height: 164px;

  animation: image-fade 0.35s ease-in-out;
  transition: filter 0.4s ease-in-out;
}
@keyframes image-fade {
  0% {
    filter: blur(2px);
  }

  100% {
    filter: blur(0px);
  }
}
@media (max-width: $phone-breakpoint) {
  .welcome_text_image {
    height: 128px;
  }
  .welcome_text {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .welcome_text_image_container {
    margin-left: 0;
  }
}

.home-teaser-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  max-width: $home-card-max-width;
  text-align: center;
  padding-top: 32px;
  margin: 0 auto;
}

.home-teaser {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 16px 16px 16px;
  border: 1px solid $light-blue;
  border-radius: 8px;
  height: 100%;
}

@media (min-width: 768px) {
  .home-teaser-container {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }
}

.ai-teaser-button {
  border-radius: 100vw !important;
  color: $text-color !important;
  background-color: transparent !important;
  border: 1px solid white !important;
  transition: all 0.4s ease !important;

  margin-top: auto;
  align-self: center;
  &:hover:not(:disabled) {
    border: 1px solid white !important;
    color: white !important;
    background-color: $primary-blue !important;
  }
  a:hover {
    text-decoration: none !important;
  }
}
.home-row {
  padding-bottom: 24px;
}
.home-row:last-child {
  padding-bottom: 64px;
}
</style>
