import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import Ripple from "primevue/ripple";
import "primeicons/primeicons.css";
import "./assets/main.scss";

import { initStats } from "@/services/WebStats";

initStats();

const pinia = createPinia();

createApp(App)
  .use(pinia)
  .use(router)
  .use(PrimeVue, {
    theme: {
      preset: Aura,
      options: {
        // deactivates darkMode for now, use "system" to activate
        darkModeSelector: ".archeion-dark",
      },
    },
  })
  .directive("ripple", Ripple)
  .mount("#app");
