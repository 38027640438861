<script setup lang="ts"></script>

<template>
  <div class="loading-text-shimmer">
    <div class="shimmer-background shimmer-content-line" />
    <div class="shimmer-background shimmer-content-line" />
    <div class="shimmer-background shimmer-content-line" />
    <div class="shimmer-background shimmer-content-line shimmer-end" />
  </div>
</template>

<style lang="scss">
@use "@/assets/variables.scss" as *;
.loading-text-shimmer {
  margin-top: 24px;
}

.shimmer-content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}
.shimmer-background {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: $highlight-color;
  background: linear-gradient(
    to right,
    $shimmer-color-1 4%,
    $shimmer-color-2 25%,
    $shimmer-color-3 36%
  );
  background-size: 1200px 100%;
}

.shimmer-end {
  width: 40%;
}
@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
</style>
