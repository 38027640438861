import COUNTRY_CODES from "@/assets/json/ISO3166-1.countries.json";

import CH from "@/assets/images/countries/CH.png";
import US from "@/assets/images/countries/US.png";
import GB from "@/assets/images/countries/GB.png";
import BR from "@/assets/images/countries/BR.png";
import BE from "@/assets/images/countries/BE.png"; // BE (Belgium) is used for Europe Union
import TW from "@/assets/images/countries/TW.png";
import AU from "@/assets/images/countries/AU.png";
import JP from "@/assets/images/countries/JP.png";
import SG from "@/assets/images/countries/SG.png";
import KR from "@/assets/images/countries/KR.png";

type COUNTRY_IMAGE_URL = {
  [key in keyof typeof COUNTRY_CODES]?: string;
};

export const COUNTRY_IMAGE_URLS: COUNTRY_IMAGE_URL = {
  CH: CH,
  US: US,
  GB: GB,
  BR: BR,
  BE: BE,
  TW: TW,
  AU: AU,
  JP: JP,
  KR: KR,
  SG: SG,
};
