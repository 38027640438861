import Clarity from "@microsoft/clarity";

const hasStats = !window.location.href.includes("//localhost");

export function initStats() {
  if (hasStats) {
    Clarity.init("oxbh17q0k7");
  }
}

export function addStatsTag(key: string, value: string) {
  if (hasStats && key && value) {
    Clarity.setTag(key, value); //"compare_selected_country", event.value.name);
  }
}
