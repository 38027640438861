<script setup lang="ts">
import { ref, watchEffect, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import logoWhiteUrl from "@/assets/images/AIPO_Logo_W_1.svg";
import logoBlueUrl from "@/assets/images/AIPO_Logo_W_1_blue2.svg";

const route = useRoute();
const router = useRouter();
const isNavigating = ref(false);
const isMobileMenuOpen = ref(false);
const logoImageSource = ref(logoBlueUrl);
onMounted(() => {
  const CSS_CLASS = "logo-hover-effect";
  const title = document.querySelector(".header-title");
  const logo = document.querySelector(".header-logo-image") as HTMLImageElement;

  if (title && logo) {
    const addHoverEffect = () => {
      title.classList.add(CSS_CLASS);
      logo.classList.add(CSS_CLASS);
      logo.src = logoWhiteUrl;
    };

    const removeHoverEffect = () => {
      title.classList.remove(CSS_CLASS);
      logo.classList.remove(CSS_CLASS);
      logo.src = logoBlueUrl;
    };

    title.addEventListener("mouseenter", addHoverEffect);
    title.addEventListener("mouseleave", removeHoverEffect);
    logo.addEventListener("mouseenter", addHoverEffect);
    logo.addEventListener("mouseleave", removeHoverEffect);

    onUnmounted(() => {
      title.removeEventListener("mouseenter", addHoverEffect);
      title.removeEventListener("mouseleave", removeHoverEffect);
      logo.removeEventListener("mouseenter", addHoverEffect);
      logo.removeEventListener("mouseleave", removeHoverEffect);
    });
  }
});

const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};
watchEffect(() => {
  isNavigating.value = true;
  setTimeout(() => {
    isNavigating.value = false; // Finish navigation with a slight delay to avoid flicker
  }, 50);
});

const isLinkActive = (linkPath: string): boolean => {
  if (isNavigating.value) return false;

  return linkPath === route.path;
};
const navigate = (path: string) => {
  isMobileMenuOpen.value = false;
  if (!isLinkActive(path)) {
    router.push(path);
  }
};
</script>

<template>
  <slot />
  <div class="header">
    <div class="header-nav">
      <div class="header-logo-container">
        <a href="/"
          ><img class="header-logo-image" :src="logoImageSource" alt="Logo"
        /></a>
        <div class="header-title-container">
          <a class="header-title" href="/">AI Policy Observatory</a>
        </div>
      </div>

      <div
        class="header-navigation-container"
        :class="{
          'header-navigation-links-mobile': isMobileMenuOpen,
        }">
        <div
          class="header-navigation-item"
          @click.prevent="navigate('/')"
          :class="{ 'header-navigation-item-active': isLinkActive('/') }">
          <a href="/">Home</a>
        </div>
        <div
          class="header-navigation-item"
          @click.prevent="navigate('/compare')"
          :class="{
            'header-navigation-item-active': isLinkActive('/compare'),
          }">
          <a href="/compare">Compare</a>
        </div>
        <div
          class="header-navigation-item"
          @click.prevent="navigate('/sources')"
          :class="{
            'header-navigation-item-active': isLinkActive('/sources'),
          }">
          <a href="/sources">Sources</a>
        </div>
        <div
          class="header-navigation-item"
          @click.prevent="navigate('/team')"
          :class="{ 'header-navigation-item-active': isLinkActive('/team') }">
          <a href="/team">Team</a>
        </div>
      </div>
      <div class="header-navigation-container__mobile">
        <i
          @click="toggleMobileMenu()"
          style="margin-top: 4px"
          class="icon-align-justify"></i>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "@/assets/variables.scss" as *;

.header {
  height: 60px; //64px; //86px;
  padding: 0 8px 0 8px;
  border-bottom: 1px solid;
  border-image: linear-gradient(to left, $background-gradient-2, $medium-blue) 1;
}
.header-logo-image {
  transform: rotate(360deg);
  height: 42px;
  //margin-top: 4px;
  transition: filter 0.4s ease-in-out;
}
.header-nav {
  color: white;
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 8px;
}

.header-title-container {
  padding-left: 24px;
}
.header-title {
  text-decoration: none;
  transition: all ease-in 0.1s;
  color: $medium-blue;
  @include very-large-title;
}

.header-navigation-container {
  //margin-top: 8px; //12px;
  display: flex;
  margin-left: auto;
  gap: 16px;
  padding-right: 24px;
  padding-left: 24px;
}
@media (min-width: $wide-breakpoint) {
  .header-navigation-container {
    text-align: center;
  }
}
.header-navigation-item a {
  @include medium-normal;
  color: $light-blue;
  text-decoration: none;
  transition: all ease-in 0.1s;
  &:hover {
    border-bottom: 1px solid white;
    color: white;
  }
}
.header-navigation-item-active a {
  @include medium-normal;
  color: white;
  border-bottom: 1px solid white;
}
.header-logo-container {
  padding-left: 16px;
  padding-right: 24px;
  display: flex;
  align-items: center;
}

.logo-hover-effect {
  color: white;
  filter: drop-shadow(0 0 5px $primary-blue);
}
.icon-align-justify {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url("@/assets/images/icons/format-justify.svg") no-repeat center;
  background-size: contain;
}
.header-navigation-container__mobile {
  padding-right: 4px;
  display: none;
  margin-left: auto;
}
@media (max-width: $phone-breakpoint) {
  .header-navigation-container__mobile {
    display: block;
  }
  .header-navigation-container {
    display: none;
  }
}
.header-navigation-links-mobile {
  display: flex;
  gap: 16px;
  text-align: center;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  color: black;
  background-color: $background-gradient-2;
  padding: 10px;
  border: 1px solid $medium-blue;
  margin: 6px;
  box-shadow: $box-shadow;
  z-index: 99;
  user-select: none;
}
</style>
